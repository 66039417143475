import { useInView } from 'react-intersection-observer'

import Link from 'next/link'

import { EventsBar } from '../../components/events-bar/events-bar'
import { MainMenu } from '../main-menu/main-menu'

import LogoSVG from './img/aviadev-logo.svg'

import CSS from './header.module.css'


const otherEvents = [
    {
        id: 1,
        title: 'FHS World',
        url: 'https://www.futurehospitality.com/world',
        target: true
    },
    {
        id: 2,
        title: 'FHS Saudi Arabia',
        url: 'https://www.futurehospitality.com/sa',
        target: true
    },
    {
        id: 4,
        title: 'IDEEA',
        url: 'https://www.ideea-forum.com',
        target: true
    },
    {
        id: 5,
        title: 'AHIF',
        url: 'https://www.ahif.com',
        target: true
    },
    {
        id: 6,
        title: 'SAHIC',
        url: 'https://www.sahic.com',
        target: true
    }
]

export const Header = ({ mainMenu }) => {

    const handleMainMenu = () => {
        if ( !mainMenu ) return
        return <MainMenu mainMenu={mainMenu} otherEvents={otherEvents}/>
    }

    const [menuTrigger, inView, entry] = useInView({
		threshold: 0.1,
	})

    const [hideEventsBarTrigger, headerInView, header] = useInView({
		threshold: 0,
        initialInView: true
	})

    return (
        <>
            <span ref={menuTrigger}>
                <EventsBar 
                    hidden={ !headerInView ? true : false }
                    thisEvent={'AviaDev'} 
                    events={otherEvents} 
                    theme={{
                        background: 'var(--dark)',
                        text: 'white',
                        accent: 'var(--accent)'
                    }}
                />
            </span>

            <header ref={hideEventsBarTrigger} className={`${CSS.header} ${ !inView && CSS.fixed }`}>
                
                <a href={'/'} aria-label='Aviadev Home'>
                    <LogoSVG className={CSS.brand}/>
                </a>

                <div className='flex'>
                    <div className={CSS.venue}>
                        <p><span className='fw-600 fs-6'>11-13 June 2025</span> <br/>Golden Tulip Zanzibar Airport, Zanzibar Tanzania</p>
                    </div>

                    <div className={CSS.ctas}>
                        <a href="https://www.eventbooking.uk.com/aviadev-2025" className={`${CSS.ctaButton} fw-600 caps`}>Register Now</a>
                        {/* <a href="/keep-me-updated" className={`${CSS.ctaButton} fw-600 caps`}>Keep me updated</a> */}
                    </div>


                    { handleMainMenu () }
                </div>

            </header>
        </>
    )
}

export const HeaderShim = ({ mobileOnly, desktopOnly }) => (
    <div className={`${CSS.shim} ${mobileOnly && CSS.mobileOnly} ${desktopOnly && CSS.desktopOnly}`}></div>
)