import Link from 'next/link'
import Image from 'next/image'

import { ArrowButton } from '../../../../components/arrow-button/arrow-button'
import { ButtonSocial } from  '../../../../components/button-social/button-social'
import { TileGrid, Tile } from '../../../../theme/2023/layout/tile-grid/tile-grid'

import LogoSVG from '../../../../global/header/img/aviadev-logo-inverse.svg'

import CSS from './footer.module.css'

export const Footer = ({ socialConnections, contactInfo, hubspotForm }) => {

    const handleSocialConnections = () => {

        if ( !socialConnections ) return

        return socialConnections.map( connection => {
            return (
                <ButtonSocial 
                    key={connection.id} 
                    icon={connection.icon} 
                    url={connection.hyperlink} 
                    alt={connection.label}
                    decal={'bg-primary'}
                />
                
            )
        })
    }

    const handleContactInfo = () => {

        if ( !contactInfo ) return

        return (
            <div className="mt-sm formatted">
                <h5>Contact us</h5>
                <div dangerouslySetInnerHTML={{__html: contactInfo }} />
            </div>
        )

    }



    return (
        <footer className="footer block bg-dark" style={{ position: `relative`}}>
            <div className={`${CSS.container} formatted c-white`}>
    
                <section className={CSS.section}>
                    <h5>Organisers</h5>
                    <div className="mt-sm">
                        <a className={CSS.partnerLogo} href="https://www.thebench.com" target='_blank' rel='noreferrer' aria-label="Organiser">
                            <LogoSVG />
                        </a>
                    </div>
                    <div className="mt-sm">
                        <a className={CSS.partnerLogo} href="https://www.thebench.com" target='_blank' rel='noreferrer' aria-label="Organiser">
                            <Image src="/assets/site/the-bench-inverse@2x.png" width={221} height={92} alt="The Bench" />
                        </a>
                    </div>
                </section>

                <section className={CSS.section}>
                    <h5>Contact us</h5>
                    <div className={CSS.contacts}>
                        <div className={CSS.contactsPhoto}>
                            <Image src="/assets/site/jon-and-mala-2024.jpg" layout={`responsive`} width={800} height={800} alt="Jon and Mala" />
                        </div>
                        <div dangerouslySetInnerHTML={{__html: contactInfo }} />
                    </div>
                </section>
    
                <section className={CSS.section}>
                    <h5>Join the conversation</h5>
                    <h3>#AviaDev</h3>
                    <div className="flex gap-xxs">
                        { handleSocialConnections() }
                    </div>
                    <div className="mt-md">
						<ArrowButton href={`/keep-me-updated`} solid theme={{ arrow: `white`, border: `var(--accent)`, label: `white`}} >KEEP ME UPDATED</ArrowButton>
					</div>
                </section>               

            </div>
                
            <div className="mt-sm c-white">
                <p><small>
                    © {new Date().getFullYear()} The Bench
                </small></p>
            </div>

        </footer>
    )
    
}