import Image from 'next/image'

import CSS from './tile-grid.module.css'



export const TileGrid = ({ children, right }) => {
    return (
        <div className={`${CSS.grid} ${right ? CSS.right : ''}`} >
            { children }
        </div>
    )
}


export const Tile = ({ src, double, hideMobile }) => {
    return (
        <div className={`${CSS.tile} ${double ? CSS.double : ''} ${hideMobile ? CSS.hideMobile : ''}`}>
            <Image src={src} layout={`responsive`} width={double ? 1600 : 800} height={800}/>
        </div>
    )
}


export const TileSpacer = ({ hideMobile }) => <div className={`${CSS.spacer} ${hideMobile ? CSS.hideMobile : ''}`}></div>
